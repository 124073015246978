













import { View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { Context, Listing, Office } from 'client-website-ts-library/types';
import { ContextItem, ContextItemType } from 'client-website-ts-library/types/Context';
import { FormConstructorData } from 'client-website-ts-library/types/Forms';
import { Component, Mixins } from 'vue-property-decorator';

import Form from '../components/Forms/Form.vue';

@Component({
  components: {
    Form,
  },
})

export default class PurchaserContractForm extends Mixins(View) {
  private listing: Listing | null = null;

  private formData: FormConstructorData | null = null;

  mounted() {
    const listingId = this.$route.query.listingId;

    if (listingId) {
      this.loadListing(listingId.toString());

      if (!this.listing) {
        API.Slugs.Resolve(listingId.toString())
          .then((resolvedSlug) => {
            if (resolvedSlug.Type === 'Listing') {
              this.loadListing(resolvedSlug.Id);
            }
          });
      }
    }
  }

  loadListing(listingId: string) {
    API.Listings.Get(listingId as string).then((listing) => {
      fetch('https://api.ipify.org?format=json')
        .then((x) => x.json())
        .then(({ ip }) => {
          this.listing = listing;
          this.formData = {
            ClientWebsiteId: Config.Website.Id,
            Items: [
              {
                Type: ContextItemType.Listing,
                Id: listing.Id,
              },
              {
                Type: ContextItemType.CustomData,
                Id: listing.Id,
                Key: 'IP Address',
                Value: ip,
              } as ContextItem,
            ],
          };
        });
    });
  }
}
